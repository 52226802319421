<template>
    <v-container v-if="team != null" class="content pt-8 px-0" fluid>
        <v-row no-gutters>
            <v-col>
                <v-card color="box" tile>
                    <axz-img height="238px" :id="team.gamebanner" />
                    <v-container fluid>
                        <v-row align="center">
                            <v-col cols="3">
                                <axz-img :id="team.gameimage" contain height="50px" />
                            </v-col>
                            <v-divider vertical />
                            <v-col>
                                <span class="d-block text-h6 pb-1">{{team.gameshort}} {{team.name}}</span>
                                <span class="d-block text-body-1 text-uppercase grey--text">{{team.gamename}}</span>
                                <span class="d-block text-body-2 grey--text">Gegründet {{UTCtoLocal(team.founded)}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-divider class="my-4" />
                        </v-row>
                        <v-row>
                            <v-col>
                                <span class="d-block text-uppercase">Über das Team</span>
                                <p>
                                    {{team.about}}
                                </p>
                            </v-col>
                            <v-divider vertical />
                            <v-col>
                                <span class="d-block text-uppercase">Erfolge</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters>     
            <v-container grid-list-xl fluid>
                <v-layout row warp justify-space-around>
                    <v-flex shrink v-for="(member, i) in team.members" :key="i" >
                        <v-container class="player-box">
                            <v-row justify="center" align="center">
                                <v-col>
                                    <v-avatar size="180" color="box">
                                        <axz-img dummy="trikot_aXz.png" /> <!-- :id="member.image" -->
                                    </v-avatar>
                                </v-col>
                                <v-col>
                                    <span class="d-block text-overline">{{member.role}}</span>
                                    <span class="d-block text-body-1">'{{member.displayname}}'</span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: [ 'team_id' ],
        data() {
            return {
            }
        },
        computed:{
            team() {
                return this.$db.teams.public.find(t => t.ID == this.team_id);
            }
        },
        mounted(){
            this.$db.teams.loadPublic();
        }
    }
</script>

<style scoped>

    .player-box {
        width: 400px;
    }

    .team-player{
        background-color: var(--v-box-base);
        width: 180px;
        height: 180px;
        /*margin: 0px auto;*/
        border-radius: 90px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-y: 6px;
    }
</style>